import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ListFilter, Tag } from '@parashift/shared/models';
import { deepCopy } from '@parashift/shared/utils';
import { TagService } from '../airbrush/tag.service';
import { JsonApiQueryData } from '../airbrush/base';

@Injectable({
  providedIn: 'root'
})
export class TagsRegister {

  tags: Tag[] = [];
  plainTags: Tag['plainModel'][] = [];

  private tenant_id: number;

  constructor(private tagService: TagService) {}

  init(tenant_id: number): Observable<boolean> {
    if (this.tenant_id && this.tenant_id === tenant_id) {
      return new Observable((observer: Observer<boolean>) => {
        observer.next(true);
        observer.complete();
      });
    } else {
      this.tenant_id = tenant_id;
      return this.fetchTags();
    }
  }

  reload(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.fetchTags().subscribe({
        next: () => {
          observer.next(true);
          observer.complete();
        },
        error: () => {
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  addTag(name: string): Observable<Tag['plainModel'] | undefined> {
    return new Observable((observer: Observer<Tag['plainModel'] | undefined>) => {
      const newTag = new Tag({
        name,
        tenant_id: this.tenant_id
      });
      this.tags.push(newTag);
      this.plainTags.push(newTag.plainModel);
      observer.next(newTag.plainModel);
      observer.complete();
    });
  }

  getByName(term: any): Tag['plainModel'] {
    return this.plainTags.find(tag => tag.name === term);
  }

  getByNames(names: string[] = []): Tag['plainModel'][] {
    const tags = [];

    names.forEach(name => {
      const result = this.plainTags.find(tag => tag.name === name);

      if (result) {
        tags.push(result);
      }
    });

    return this.sortTagsAlphabetically(tags);
  }

  getUnusedTags(used_tags: Tag['plainModel'][]): Tag['plainModel'][] {
    const tags = deepCopy(this.plainTags);

    used_tags.forEach(tag => {
      const index = tags.findIndex(item => item.name === tag.name);
      if (index > -1) {
        tags.splice(index, 1);
      }
    });

    return this.sortTagsAlphabetically(tags);
  }

  tagExists(name: string): boolean {
    const index = this.tags.findIndex(tag => tag.name === name);
    return index > -1 ? true : false;
  }

  private fetchTags(): Observable<boolean> {
    const listFilter = new ListFilter({
      page: { number: 1, size: 1000 },
      sorting: { direction: '', field: 'name' }
    });

    return new Observable((observer: Observer<boolean>) => {
      this.tagService.findAll(listFilter, this.tenant_id).subscribe({
        next: (tags: JsonApiQueryData<Tag>) => {
          this.tags = tags.getModels();
          this.plainTags = this.tags.map(tag => tag.plainModel);
          observer.next(true);
          observer.complete();

        },
        error: () => {
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  getFilterItems(addOption?: 'all' | 'any') {
    const items = this.tags.map(tag => {
      return !addOption ? {
        key: tag.name,
        value: tag.name,
        search_index: tag.name
      } : {
        key: tag.name,
        value: tag.name
      };
    });

    if (addOption === 'all') {
      items.unshift({ key: 'true', value: 'none' });
    } else if (addOption === 'any') {
      items.unshift({ key: 'false', value: 'any' });
    }

    return items;
  }

  private sortTagsAlphabetically(tags: Tag['plainModel'][]): Tag['plainModel'][] {
    return tags.sort((a, b) => a.name.localeCompare(b.name));
  }
}
