// eslint-disable-next-line @typescript-eslint/no-namespace
namespace c {
  export function execute<Type>(original: unknown): Type {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let copy: any;

    if (original === null) {
      copy = original;
    } else if (original instanceof Date) {
      copy = new Date(original.getTime());
    } else if (Array.isArray(original)) {
      copy = [];
      original.forEach(v => copy.push(v));
      copy = copy.map((n: unknown) => c.execute(n));
    } else if (typeof original === 'object' && Object.keys(original).length !== 0) {
      copy = { ...original };
      Object.keys(copy).forEach(k => copy[k] = c.execute(copy[k]));
    } else {
      copy = original;
    }
    return copy;
  }
}

function deepCopy<Type>(original: Type): Type {
  return c.execute<Type>(original);
}

export { deepCopy };
