import { Model, Attribute } from './decorators';
import { BaseApiModel } from './base';
import { TagType } from '@parashift/shared/types';

export interface TagPlainModel {
  id: string;
  created_at: string;
  name: string;
  tenant_id: string;
  updated_at: string;
}

@Model({ type: TagType })
export class Tag extends BaseApiModel<TagPlainModel> {

  @Attribute()
  created_at: string;

  @Attribute()
  name: string;

  @Attribute()
  tenant_id: string;

  @Attribute()
  updated_at: string;
}
